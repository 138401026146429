.xwords .row {
  font-size: 90%;
}

.xwords .row fieldset.item {
  border: 1px solid #777788;
  margin: 1em 0em;
  text-align: left;
  background-color: transparent;
}
.xwords .row.two fieldset.item {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}
.xwords .row.two fieldset.item:not(:last-child) {
  margin-right: 2%;
}
.xwords .row.three fieldset.item {
  display: inline-block;
  width: 32%;
  vertical-align: top;
}
.xwords .row.three fieldset.item:not(:last-child) {
  margin-right: 2%;
}

.xwords .row fieldset.item legend.title {
  padding: 2px 5px;
  background: #2f5376;
  color: #ffffff;
  border-bottom: 1px solid #000000;
}

.xwords .row fieldset.item div.body {
  margin: 1em;
  line-height: 1.5;
  text-align: center;
}

.xwords .row fieldset.item.category div.body span {
  margin: 0 5px;
}
.xwords .row fieldset.item.initial div.body span {
  margin: 0 8px;
}

.xwords .row fieldset.item div.body span.info {
  font-size: 90%;
  color: #333333;
}
.xwords .row fieldset.item.recently div.body span.info {
  margin-left: 3px;
}
.xwords .row fieldset.item.ranking div.body span.info {
  margin-right: 3px;
}

.xwords .row fieldset.item.search div.body dl {
  margin: 1em 0;
}
.xwords .row fieldset.item.search div.body dl dt {
  width: 25%;
  text-align: right;
  float: left;
  margin: 0 0.5em 0 0;
}
.xwords .row fieldset.item.search div.body dl dd {
  text-align: left;
  clear: right;
}

.xwords .row fieldset.item div.body a {
  font-weight: bold;
}

.xwords .row fieldset.item div.body ul {
  text-align: left;
  padding-left: 0;
}

.xwords .row fieldset.item div.body ul li {
  list-style-type: none;
}

.xwords .row fieldset.item div.body .term {
  text-align: left;
  background-color: #f5f7f7;
}
.xwords .row fieldset.item div.body .definition {
  text-align: left;
}

.xwords .breadcrumbs {
  font-size: 80%;
}

.xwords h4.title {
  text-align: center;
}

.xwords .categories .category {
  margin: 20px 0;
}
.xwords .categories .category .link {
  padding: 3px 1px;
  background-color: #cee1eb;
  font-size: 1.1em;
  font-weight: bold;
}
.xwords .categories .category .num {
  margin-left: 10px;
  font-size: 0.6em;
  color: #cd5c5c;
}

.xwords .entries .entry {
  margin: 20px 0;
}
.xwords .entries .entry .link {
  padding: 3px 1px;
  background-color: #cee1eb;
  font-size: 1.1em;
  font-weight: bold;
}
.xwords .entries .entry .description {
  margin: 0.5em 0 0.5em 1em;
  font-size: 0.9em;
}
.xwords .entries .entry .description .definition {
  margin-left: 10px;
}

.xwords > .entry > .description {
  margin: 1em;
}
.xwords > .entry > .description .yomi {
  margin: 1em 0;
}
.xwords > .entry > .description .note {
  margin: 1em 0;
  text-align: right;
  font-size: 0.9em;
}
.xwords .entries .pagenavi {
  text-align: right;
  font-size: 0.9em;
}
