.pubmedpdf nav.header::after,
.pubmedpdf .years::after,
.pubmedpdf .author-group::after,
.pubmedpdf .journal-group::after {
  content: "";
  display: block;
  clear: both;
}

.pubmedpdf nav.header .count {
  float: left;
}

.pubmedpdf nav.header .link {
  float: right;
}

.pubmedpdf h4.pt {
  border-bottom: 1px dashed #555;
  width: 100%;
}

.pubmedpdf span.initial:not(:first-child) {
  margin-left: 0.5em;
}

.pubmedpdf .years .year {
  float: left;
  width: 12%;
}

.pubmedpdf .journal-group .title {
  margin: 0.8em 0 0.3em;
  padding: 5px;
  background-color: #eff4f9;
}
.pubmedpdf .journal-group .journal {
  float: left;
  width: 33%;
}

.pubmedpdf .author-group .author {
  float: left;
  width: 25%;
}

.pubmedpdf .papers .paper {
  margin: 0.5em 0;
  padding: 0.5em;
  background-color: #eff4f9;
}

.pubmedpdf .paper_desc table td.head {
  width: 150px;
}