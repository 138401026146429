.cosmodb {
  font-size: 95%;
}

.cosmodb th {
  color: #ffffff;
  background-color: #802d2d;
  padding: 2px;
  vertical-align: middle;
  text-align: left;
}

.cosmodb th a {
  color: #ffffff;
}

.cosmodb th a:hover {
  color: #ff6600;
}

.cosmodb hr {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #dedbde;
}

.cosmodb figure {
  margin: 0;
}

.cosmodb .list .view-switcher {
  font-size: 95%;
  text-align: right;
}

.cosmodb .list .pagenavi {
  margin: 5px 0;
  font-size: 95%;
}

.cosmodb .list .pagenavi .pages {
  margin-left: 10px;
  font-weight: bold;
}

.cosmodb .list .list-sortbar {
  margin: 5px 0;
  text-align: right;
}

.cosmodb .list .list-sortbar select {
  margin-right: 3px;
}

.cosmodb .list .list-sortbar .formButton {
  background: white;
  border: 1px solid;
}

.cosmodb .list .list-view .list-table {
  margin: 10px 0;
  border-collapse: collapse;
  border: 1px solid #dedbde;
  text-align: left;
}

.cosmodb .list .list-view .list-table th {
  padding: 2px;
  vertical-align: middle;
}

.cosmodb .list .list-view .list-table td {
  padding: 3px;
  border: 1px solid #dedbde;
}

.cosmodb .list .thumbnail-view .thumbnail-table {
  width: 100%;
  border-collapse: collapse;
}

.cosmodb .list .thumbnail-view .thumbnail-table td {
  padding: 0px;
  border-width: 0px;
  vertical-align: top;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table th a {
  color: #ffffff;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table th a:hover {
  color: #ff6600;
  text-decoration: none;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table {
  border-collapse: collapse;
  border: 1px solid #dedbde;
  text-align: left;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table td {
  padding: 3px;
  border: 1px solid #dedbde;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table td img {
  border: 2px solid transparent;
}

.cosmodb .list .thumbnail-view .thumbnail-table td .list_table td img:hover {
  border-color: #ff6600;
}

.cosmodb .detail table td {
  padding: 3px;
}

.cosmodb .detail .label_header {
  color: #db4d4d;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #dddddd;
}

.cosmodb .detail .h_under {
  margin: 10px 0 30px;
  font-weight: bold;
}

.cosmodb .detail .images {
  text-align: center;
}

.cosmodb .detail .images td img {
  border: 2px solid transparent;
}

.cosmodb .detail .images td img:hover {
  border-color: #ff6600;
}

.cosmodb .detail .list_table td.even {
  width: 120px;
}

.cosmodb .detail .h {
  width: 100%;
  font-size: 120%;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  margin-top: 40px;
  color: #db4d4d;
  font-weight: bold;
}

.cosmodb .detail .keywords>ul {
  padding-left: 0;
}

.cosmodb .detail .keywords>ul>li {
  list-style-type: none;
  font-style: italic;
}

.cosmodb .detail .keywords>ul>li>ul {
  padding-left: 20px;
  font-style: normal;
}

.cosmodb .detail .keywords>ul>li>ul li {
  list-style: square inside;
  color: #2F5376;
}

.cosmodb .detail .datatree ul {
  margin: 0;
  padding: 0;
  position: relative;
}

.cosmodb .detail .datatree ul li {
  margin: 0 0 0 14px;
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
  border-left: 1px solid #cccccc;
}

.cosmodb .detail .datatree ul li div {
  padding-left: 10px;
  position: relative;
}

.cosmodb .detail .datatree ul li div::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -1px;
  bottom: 50%;
  width: 7px;
  border: 1px solid #cccccc;
  border-top: 0 none transparent;
  border-right: 0 none transparent;
  border-radius: 0 0 0 0px;
}

.cosmodb .detail .datatree ul>li:last-child {
  border-left: 1px solid transparent;
}

.cosmodb .detail .datatree li.root {
  background: url(images/icon-root.png) no-repeat 6px 0;
}

.cosmodb .detail .datatree li.root>div {
  visibility: hidden;
}

.cosmodb .detail .datatree li.dir>div {
  background: url(images/icon-folder-open.png) no-repeat 8px 0;
  text-indent: 18px;
}

.cosmodb .detail .datatree li.file>div {
  background: url(images/icon-file.png) no-repeat 8px 0;
  text-indent: 14px;
}

.cosmodb .detail .news {
  border-bottom: 1px dashed #ddd;
}

.cosmodb .detail .news .date {
  display: inline-block;
  width: 100px;
}

.cosmodb .detail .news .filename {
  display: inline-block;
  width: calc(100% - 200px);
}

.cosmodb .detail .news .user {
  display: inline-block;
  width: 100px;
}

.cosmodb .detail .links .title {
  font-weight: bold;
}

.cosmodb .detail .links .link span {
  vertical-align: top;
  display: inline-block;
}

.cosmodb .detail .links .link .name {
  width: 50%;
}

.cosmodb .detail .links .link .note {
  width: 30%;
}

.cosmodb .detail .links .link .user {
  width: 10%;
}