*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  min-width: 880px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  background-color: #c6c6c6;
  margin: 0;
  font-size: 90%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 1.802em;
}

h2 {
  font-size: 1.602em;
}

h3 {
  font-size: 1.424em;
}

h4 {
  font-size: 1.266em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

a {
  color: #333333;
  text-decoration: none;
}

a:hover {
  color: #ff6600;
  text-decoration: underline;
}

table {
  width: 100%;
}

td,
th {
  padding: 10px;
}

th,
td,
.head {
  vertical-align: top;
}

.foot {
  vertical-align: middle;
}

th,
.head,
.foot {
  font-weight: bold;
  background-color: #cee1eb;
}

.even {
  background-color: #eff4f9;
}

.odd {
  background-color: #f5f7f7;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.block .blockTitle {
  padding: 5px 10px;
  color: #ffffff;
  background: #802d2d;
  font-weight: bold;
}

.block .blockContent {
  border-left: 1px solid#cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #dddddd;
  padding: 3px;
  margin-bottom: 8px;
}

#page {
  margin: 5px 15px;
}

#header {
  color: #ffffff;
  background-color: #802d2d;
}

#header .logo {
  float: left;
  display: block;
  height: 40px;
  width: 180px;
  background-image: url(images/logo.png);
}

#header .utility {
  float: right;
  padding-top: 7px;
  padding-right: 15px;
}

#header .menubar {
  height: 22px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background-color: #464646;
  color: #ffffff;
}

#header .mainmenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#header .mainmenu li {
  margin: 0 10px;
  line-height: 20px;
  font-size: 0.8em;
  width: 100px;
  display: inline-block;
  text-align: left;
  font-weight: bold;
}

#header .mainmenu li:first-child {
  text-align: center;
}

#header .mainmenu li>a,
#header .mainmenu li>span {
  display: inline-block;
  width: 100%;
  color: #ffffff;
  background-color: #464646;
  text-decoration: none;
}

#header .mainmenu li>a:hover,
#header .mainmenu li>span:hover {
  background-color: #6f6f6f;
}

#header .mainmenu .submenu {
  position: relative;
  float: left;
  background-color: lightyellow;
  border: 1px solid #000000;
  z-index: 10;
  animation: fadeIn 1s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#header .mainmenu .submenu .item {
  display: block;
  padding: 1px 5px;
  white-space: nowrap;
  text-decoration: none;
}

#header .mainmenu .submenu .item:not(:first-child) {
  border-top: 1px solid #000000;
}

#header .mainmenu .submenu .item:hover {
  background-color: yellow;
}

#footer {
  height: 42px;
  border-top: 2px solid #000000;
  color: #ffffff;
  background-color: #802d2d;
  text-align: center;
}

#footer .copyright {
  line-height: 38px;
  display: block;
  font-size: 90%;
}

#main {
  background-color: #ffffff;
  padding: 15px;
  min-height: 500px;

}